import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import Toptitle from "../components/toptitle"
import styled from "styled-components"

const Materials = () => (
  <Layout>
    <SEO title="原材料について" />
    <div>
      <section className="w-full lg:mr-0 lg:ml-auto lg:w-2/3">
        <Toptitle>Materials</Toptitle>
        <div className="pt-32 lg:mt-16 lg:pt-0">
          <h1 className="font-mincho text-3xl text-primary">原材料について</h1>
        </div>
        <div className="my-8">
          <div className="mb-8">
            <p className="font-gothic leading-loose text-neutral">
              原材料は
              <a href="https://www.jic-bestork.co.jp/" className="underline">
                日本インシュレーション株式会社
              </a>
              が提供する「タイカライトウッド」を使用しております。
              <br />
              <br />
              「タイカライトウッド」は内装制限のある部位に使用できる不燃認定を取得した建材でありながら、リブ・波形・幾何学模様・モール・レリーフなど幅広いデザイン加工が可能です。
              <br />
              きめ細やかな質感と高い加工性を有する白色素材で、匂わない、腐らない、伸縮がほぼないことが特徴です。
              <br />
              <br />
              加工後は照明演出と組み合わせることで、深い陰影や淡いグラデーションの陰影を作り出し、見る方向で表情を変える・外光の変化で雰囲気が変わるといった想像以上の意匠演出効果をもたらします。
            </p>
          </div>
          <YoutubeAsepect>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/kMNlMwv3_OE"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="absolute top-0 left-0 h-full w-full"
            ></iframe>
          </YoutubeAsepect>
        </div>
      </section>
    </div>
  </Layout>
)

const MobileWrapper = styled.div`
  @media screen and (max-width: 1023px) {
    display: none;
  }
  @media screen and (min-width: 1024px) {
    width: 50%;
  }
`
const YoutubeAsepect = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%;
`

export default Materials
